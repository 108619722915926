@import 'base';

html { font-size: 80%; box-sizing: border-box; }
body { color: #000 !important; background: #fff !important;  }

.l-Page__printLogo {
    float: right;
    margin: 0 0 10px 10px;
}

.l-Site__skipLinks,
.l-Site__compatWarnings,
header,
footer,
.l-Site__hero,
.l-Site__footerarea,
.l-Page__crumbs,
.l-Page__sidebar,
.l-Page__farmers-section-callout,
.BlogPost__related,
.Recipe__related,
.ShareButtons,
.Pagination,
.FilterForm {
	display: none !important;
}
.l-Site__container {
	max-width: unset !important;
	padding: 0 !important;
    margin: 0 !important;
}
.l-Page__block {
	max-width: unset !important;
	margin-top: 2rem !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
}
.l-Page__component {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
    max-width: none !important;
}
.BlogPost {
	max-width: unset !important;
	padding-left: 0 !important;
}
.BlogPost__MetaData {
	position: relative !important;
}

.MetaList {
	margin-bottom: 1.5rem !important;
}
.MetaList__item {
	display: block !important;
	padding: 0 !important;
	margin: 0 0 0.5rem 0 !important;
}
.MetaItem {
	display: block;
	overflow: hidden;
	font-size: 1rem !important;
}
.MetaItem__icon {
	display: none !important;
}
.MetaItem > .MetaItem__value,
.MetaItem > .MetaItem__list {
	margin: 0 0 0 14rem !important;
	padding: 0 !important;
	display: block;
}
.MetaItem__label,
.MetaItem.-nolabel .MetaItem__label {
	position: relative !important;
	height: auto !important;
	width: auto !important;
	clip: unset !important;
	clip-path: none !important;
	overflow: visible !important;
	font-weight: $font-weight-normal;
	padding: 0 !important;
	float: left;

	&::after {
		content: ": ";
	}
}
.Recipe {
	max-width: unset !important;
	padding-left: 0 !important;
}
.Recipe__InfoList {
	display: block !important;
}
.Recipe__InfoListItem {
	margin: 0 !important;
}
.Recipe__InfoListItem .MetaItem {
	display: block !important;
	padding: 1rem;
}
.Recipe__InfoListItem .MetaItem__value {
	display: block !important;
	text-align: left;
}
.Recipe__InfoListItem .MetaItem__value.-rating {
	margin: 0 !important;
	float: left;

	&::after {
		content: ": ";
	}
}
.Recipe__InfoListItem .MetaItem__icon.-rating {
	display: block !important;
	margin: 0 0 0 14rem !important;
	text-align: left;
	padding: 0 !important;
}
.Recipe__NutritionItems {
	grid-gap: 1.5rem !important;
}
.Recipe__NutritionLegend {
	margin-top: 1rem !important;
}

.u-print-only {
	display: block !important;
}
.u-print-hidden {
	display: none !important;
}
.u-print-visible {
	display: block !important;
}

.s-cmsContent img {
	box-shadow: none;
    max-width: 50% !important;
    max-height: 400px !important;
    width: auto !important;
    height: auto !important;
}
.s-cmsContent .embed,
.s-cmsContent iframe {
    display: none !important;
}

input[type="date"]:not([disabled]),
input[type="date"]:not([readonly]),
input[type="datetime-local"]:not([disabled]),
input[type="datetime-local"]:not([readonly]),
input[type="email"]:not([disabled]),
input[type="email"]:not([readonly]),
input[type="month"]:not([disabled]),
input[type="month"]:not([readonly]),
input[type="number"]:not([disabled]),
input[type="number"]:not([readonly]),
input[type="password"]:not([disabled]),
input[type="password"]:not([readonly]),
input[type="search"]:not([disabled]),
input[type="search"]:not([readonly]),
input[type="tel"]:not([disabled]),
input[type="tel"]:not([readonly]),
input[type="text"]:not([disabled]),
input[type="text"]:not([readonly]),
input[type="time"]:not([disabled]),
input[type="time"]:not([readonly]),
input[type="url"]:not([disabled]),
input[type="url"]:not([readonly]),
input[type="week"]:not([disabled]),
input[type="week"]:not([readonly]),
select:not([multiple]):not([disabled]),
select:not([multiple]):not([readonly]),
textarea:not([disabled]),
textarea:not([readonly]) {
	box-shadow: none;
	border: 1px solid $color-grey-800;
}
select:not([multiple]):not([disabled]),
select:not([multiple]):not([readonly]),
select:not([multiple])[disabled],
select:not([multiple])[readonly] {
    background: none;
}
.s-Form .field:not(.requiredField):not(.password) > label.left::after {
    content: '';
}
.s-Form .btn-toolbar {
	display: none;
}
.ElementGoogleMap__map {
	display: none;
}

.o-Grid--OrderResources {
    margin-top: 0 !important;
}
.o-Grid--OrderResources .o-Grid__cell {
    max-width: none;
}
.Card--OrderResources {
    display: block;
    border: 2px solid $color-orange-500;
    padding: 5px;
    box-shadow: none;

    .Card__hd {
        width: 30%;
        float: left;
        margin-right: 1rem;
        min-height: 145px;
    }
    .Card__bd {
        display: block;
        padding: 0.5rem 0 45px 33%;
        text-align: left;
    }
    .Card__footer {
        position: absolute;
        right: 5px;
        bottom: 5px;
        width: 66%;
    }
}
.ResourceOrders__Cart {
    page-break-inside: avoid;
}
.ResourceOrders__CartTitle {
    margin-top: 3rem;
}
.ResourceOrders__SelectForm {

    &.u-print-only {
        display: table !important;
    }

    label {
        padding-right: 0.5rem;
        text-align: left;
        vertical-align: middle;
        display: table-cell;
        margin-right: 0;
        width: 50%;
    }
    input {
        display: table-cell;
        vertical-align: middle;
    }
}

.Card {
    page-break-inside: avoid;
}
.o-Grid {
    display: block;
    overflow: hidden;
    margin-top: 0;
}
.o-Grid__cell {
    float: left;
    margin-left: 0;
    margin-right: 0;
    margin-top: 1rem;
    margin-bottom: 0;
    padding-right: 1rem;
    width: 100%;
    max-width: none;

    img {
        height: auto;
    }

    .o-Grid--AssociateMembers & {
        width: 20%;
        &:nth-child(5n+1) {
            clear: left;
        }
    }
    .o-Grid--BlogStacked &,
    .o-Grid--BlogMixed &,
    .o-Grid--Chemicals &,
    .o-Grid--DMSDocumentsHorizontal &,
    .o-Grid--DMSDocumentsCards &,
    .o-Grid--ImageLibrary &,
    .o-Grid--MailchimpCampaigns &,
    .o-Grid--RecipeResults &,
    .o-Grid--ResearchSmall &,
    .o-Grid--SearchResults & {
        width: 33%;
        &:nth-child(3n+1) {
            clear: left;
        }
    }
    .o-Grid--Blog &,
    .o-Grid--OrderResources &,
    .o-Grid--Pages &,
    .o-Grid--QuestionsAndAnswers &,
    .o-Grid--Recipes &,
    .o-Grid--Research &,
    .o-Grid--TilesFullWidth
    .o-Grid--TilesStacked &,
    .o-Grid--TilesHorizontal &,
    .o-Grid--TilesImage &,
    .o-Grid--Videos & {
        width: 50%;
        &:nth-child(2n+1) {
            clear: left;
        }
    }
}

img[src$=".gif" i] {
    display: none !important;
}

.Accordion {
    $root: &;

    &__button {
        &:before {
            display: none !important;
        }
    }
    #{$root}.is-initialized &__bd {
        &,
        &.is-hidden {
            max-height: 100% !important;
            visibility: visible;
            display: block;
        }
    }
}
